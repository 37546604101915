.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* ----------------
NAVBAR CSS 
-------------------*/

.nav-bg{
  background-color: #001F3F;
}
.nav-text{
  color: #FF900D;
  /* Home */
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.nav-text:hover{
  color: white;
}


/*-------------------
 HeroSection
 -------------------*/
.herosection{
  background-image: url(https://ik.imagekit.io/prakash/heroimage.jpg?updatedAt=1701630267776) /*6.521px -110.735px / 100% 160% no-repeat*/;
  background-color: lightgray;
  /* background-image: url(/static/media/Bg_heroImage.9c0bb03….png); */
  background-repeat: no-repeat;
  /* background-size: 20px 20px; */
  background-size: cover;
}
.hero-h1{
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.hero-p{
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal
}
.hero-abt-btn{
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;

  border-radius: 5px;
  background: #001F3F;
  width: 160px;
  height: 50px;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
  .hero-h1{
    font-size: 30px;
   }
  .hero-p{ 
    font-size: 16px;
  }
  .hero-abt-btn{
    font-size: 14px;
    font-weight: 400;
    width: 100px;
    height: 35px;
  }
}
@media screen and (max-width: 640px) {
 .hero-h1{
  font-size: 14px;
 }
 .hero-p{
  font-size: 8px;
 }
 .hero-abt-btn{
  font-size: 8px;
  width: 50px;
  height: 20px;
 }  
}

/*-------------------
 OUR SERVICES CARDS
 -------------------*/
.card-props{
border-radius: 5px;
border: 1px solid #FF900D;
background: #FFF;

width: 600px;
height: 270px;
flex-shrink: 0;
}

.card-props-sm{
  width: 300px;
  height: 183px;
  flex-shrink: 0;

  border-radius: 5px;
  border: 1px solid #FF900D;
  background: #FFF;
}

.h1-card-texts{
color: #001F3F;
/* text-align: center; */
/* font-family: Montserrat; */
font-size: 25px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.p-card-texts{
color: #001F3F;
/* font-family: Montserrat; */
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

/*-------------------
PAST CLIENTS
-------------------*/
.bg-client-cards{
background: linear-gradient(180deg, #FEF7EE 0%, rgba(255, 255, 255, 0.00) 50.59%, #FEF7EE 115.92%); 
}
.logo-animate-left{
  animation: bannerleft 12s linear infinite;
}
@keyframes bannerleft {
            0% { transform: translateX(0); }
            100% { transform: translateX(calc(-50% - (theme(spacing.8) / 2))); }
   }

/*-------------------
CONTACT FORM
-------------------*/
.input-contact-us{
  background: #EFEFEF;
  height: 50px;
  color:#001F3F;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 768px) {
  .contactForm-tab{
    width: 400px;
  }
}

@media screen and (max-width: 640px) {
  .contactForm-tab{
    width: 320px;
  }
  .submit-btn{
    width: 240px !important;
    font-weight: 600 !important;
  }
}

/* .input-contact-us-tab{

}
.input-contact-us-mob{

} */

.submit-btn{
  width: 300px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFA337;
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
/* .submit-btn-tab{

}
.submit-btn-mob{

} */

.errorMessage{
  margin-top: -40px;
  display: flex;
  justify-content: start;
  color:red;
}
/* .errorMessage-tab{

}
.errorMessage-mob{

} */

.message-sent-toast{
  color: white !important;
  font-size: 25px;
  font-weight: 400;
  background-color: #FFA337 !important;
}
/* .message-sent-toast-tab{

}
.message-sent-toast-mob{

} */

/*----------------
FOOTER STYLES
----------------*/

.footer-props{
  background: #001F3F;
}
.footer-addy{
  color: #FFF;
  /* font-family: Montserrat; */
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.footer-tab-view-addy{
 margin-left: 15rem;   
}
@media screen and (max-width: 1024px) {
  .footer-tab-view-addy{
    margin-left: 9rem;
  }
}
@media screen and (max-width: 860px) {
  .footer-tab-view-addy{
    margin-left: 7rem;
    margin-right: -2rem ;
  }
}